/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddressDTO
 */
export interface AddressDTO {
    /**
     * 
     * @type {number}
     * @memberof AddressDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    addressTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    unitNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    complexName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    suburb?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    provinceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    postalCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDTO
     */
    isPrimary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDTO
     */
    confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    longitude: string;
}
/**
 * 
 * @export
 * @interface ContactDTO
 */
export interface ContactDTO {
    /**
     * 
     * @type {number}
     * @memberof ContactDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    profileOwnerId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactDTO
     */
    isEmergency: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContactDTO
     */
    emergencyPriority: number;
    /**
     * 
     * @type {Array<ContactType>}
     * @memberof ContactDTO
     */
    contactType: Array<ContactType>;
    /**
     * 
     * @type {RelationshipType}
     * @memberof ContactDTO
     */
    relationshipType?: RelationshipType;
    /**
     * 
     * @type {boolean}
     * @memberof ContactDTO
     */
    isMember: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    contactProfile?: string;
}
/**
 * 
 * @export
 * @interface ContactType
 */
export interface ContactType {
    /**
     * 
     * @type {string}
     * @memberof ContactType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ContactType
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof ContactType
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface ErpcDTO
 */
export interface ErpcDTO {
    /**
     * 
     * @type {number}
     * @memberof ErpcDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    branchName: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    companyLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    contactNo: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    address: string;
}
/**
 * 
 * @export
 * @interface Option
 */
export interface Option {
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof Option
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface OtpConfirmData
 */
export interface OtpConfirmData {
    /**
     * 
     * @type {string}
     * @memberof OtpConfirmData
     */
    otp?: string;
    /**
     * 
     * @type {string}
     * @memberof OtpConfirmData
     */
    userUid?: string;
}
/**
 * 
 * @export
 * @interface PermissionDTO
 */
export interface PermissionDTO {
    /**
     * 
     * @type {string}
     * @memberof PermissionDTO
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof PermissionDTO
     */
    base64Perm: string;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDTO
     */
    ban?: boolean;
}
/**
 * 
 * @export
 * @interface PhoneDTO
 */
export interface PhoneDTO {
    /**
     * 
     * @type {number}
     * @memberof PhoneDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PhoneDTO
     */
    phoneNumber: string;
    /**
     * 
     * @type {PhoneNumberType}
     * @memberof PhoneDTO
     */
    type: PhoneNumberType;
    /**
     * 
     * @type {string}
     * @memberof PhoneDTO
     */
    extension?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneDTO
     */
    countryCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoneDTO
     */
    isPrimary: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PhoneDTO
     */
    isConfirmed: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PhoneNumberType {
    Main = 'Main',
    Work = 'Work',
    Home = 'Home',
    Alternate = 'Alternate',
    Emergency = 'Emergency'
}

/**
 * 
 * @export
 * @interface PrimaryCellData
 */
export interface PrimaryCellData {
    /**
     * 
     * @type {string}
     * @memberof PrimaryCellData
     */
    cell?: string;
}
/**
 * 
 * @export
 * @interface ProfileDTO
 */
export interface ProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    tbxPassportId: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    connectMeCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    lastUpdated?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    firstNames: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    genderIdentityId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    mainLanguageId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    passportNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    occupationalSector?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    maritalStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileDTO
     */
    numberOfChildDependents?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileDTO
     */
    numberOfAdultDependents?: number;
    /**
     * 
     * @type {Array<AddressDTO>}
     * @memberof ProfileDTO
     */
    addresses?: Array<AddressDTO>;
    /**
     * 
     * @type {Array<PhoneDTO>}
     * @memberof ProfileDTO
     */
    phoneNumbers?: Array<PhoneDTO>;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    mainCell: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    profilePhoto?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    distinctiveFeatures?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    glasses?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    contactLenses?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    falseTeeth?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    hearingAids?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    tattoos?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    piercings?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    heightAndBuild?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    hairColour?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    eyeColour?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    race?: string;
    /**
     * 
     * @type {ErpcDTO}
     * @memberof ProfileDTO
     */
    company?: ErpcDTO;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    telegramId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileDTO
     */
    userStatus?: number;
    /**
     * 
     * @type {Array<ContactDTO>}
     * @memberof ProfileDTO
     */
    contacts?: Array<ContactDTO>;
}
/**
 * 
 * @export
 * @interface RegisterData
 */
export interface RegisterData {
    /**
     * 
     * @type {string}
     * @memberof RegisterData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterData
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterData
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterData
     */
    cell?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterData
     */
    redirect?: string;
}
/**
 * 
 * @export
 * @interface RelationshipType
 */
export interface RelationshipType {
    /**
     * 
     * @type {string}
     * @memberof RelationshipType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RelationshipType
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof RelationshipType
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface SetFirstPasswordData
 */
export interface SetFirstPasswordData {
    /**
     * 
     * @type {string}
     * @memberof SetFirstPasswordData
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof SetFirstPasswordData
     */
    confirmPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof SetFirstPasswordData
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface Status
 */
export interface Status {
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    description: string;
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    cell: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    firstNames: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    base64Permissions: string;
}

/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHealthCheck: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testHealthCheck(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testHealthCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHealthCheck(options?: any): AxiosPromise<any> {
            return localVarFp.testHealthCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testHealthCheck(options?: any) {
        return TestApiFp(this.configuration).testHealthCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserAdminApi - axios parameter creator
 * @export
 */
export const UserAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAdminDeleteUser: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('userAdminDeleteUser', 'guid', guid)
            const localVarPath = `/UserAdmin/deleteuser/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAdminDisableUser: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('userAdminDisableUser', 'guid', guid)
            const localVarPath = `/UserAdmin/disableuser/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAdminListAllUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserAdmin/listallusers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PermissionDTO} permData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAdminSavePermissions: async (permData: PermissionDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'permData' is not null or undefined
            assertParamExists('userAdminSavePermissions', 'permData', permData)
            const localVarPath = `/UserAdmin/savepermissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(permData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAdminUserDetail: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('userAdminUserDetail', 'guid', guid)
            const localVarPath = `/UserAdmin/userdetail/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAdminApi - functional programming interface
 * @export
 */
export const UserAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAdminDeleteUser(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAdminDeleteUser(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAdminDisableUser(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAdminDisableUser(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAdminListAllUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAdminListAllUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PermissionDTO} permData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAdminSavePermissions(permData: PermissionDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAdminSavePermissions(permData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAdminUserDetail(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAdminUserDetail(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAdminApi - factory interface
 * @export
 */
export const UserAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAdminApiFp(configuration)
    return {
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAdminDeleteUser(guid: string, options?: any): AxiosPromise<string> {
            return localVarFp.userAdminDeleteUser(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAdminDisableUser(guid: string, options?: any): AxiosPromise<string> {
            return localVarFp.userAdminDisableUser(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAdminListAllUsers(options?: any): AxiosPromise<Array<UserDTO>> {
            return localVarFp.userAdminListAllUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PermissionDTO} permData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAdminSavePermissions(permData: PermissionDTO, options?: any): AxiosPromise<string> {
            return localVarFp.userAdminSavePermissions(permData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAdminUserDetail(guid: string, options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.userAdminUserDetail(guid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAdminApi - object-oriented interface
 * @export
 * @class UserAdminApi
 * @extends {BaseAPI}
 */
export class UserAdminApi extends BaseAPI {
    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public userAdminDeleteUser(guid: string, options?: any) {
        return UserAdminApiFp(this.configuration).userAdminDeleteUser(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public userAdminDisableUser(guid: string, options?: any) {
        return UserAdminApiFp(this.configuration).userAdminDisableUser(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public userAdminListAllUsers(options?: any) {
        return UserAdminApiFp(this.configuration).userAdminListAllUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PermissionDTO} permData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public userAdminSavePermissions(permData: PermissionDTO, options?: any) {
        return UserAdminApiFp(this.configuration).userAdminSavePermissions(permData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminApi
     */
    public userAdminUserDetail(guid: string, options?: any) {
        return UserAdminApiFp(this.configuration).userAdminUserDetail(guid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserManagerApi - axios parameter creator
 * @export
 */
export const UserManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OtpConfirmData} confirmData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerCheckPrimaryOtp: async (confirmData: OtpConfirmData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmData' is not null or undefined
            assertParamExists('userManagerCheckPrimaryOtp', 'confirmData', confirmData)
            const localVarPath = `/UserManager/checkprimaryotp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [guid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerConfirmEmail: async (token?: string, guid?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserManager/confirmemail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (guid !== undefined) {
                localVarQueryParameter['guid'] = guid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerGenerateRandomNo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserManager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterData} registerData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerRegister: async (registerData: RegisterData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerData' is not null or undefined
            assertParamExists('userManagerRegister', 'registerData', registerData)
            const localVarPath = `/UserManager/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerRegisterFromGoogle: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserManager/registergoogle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetFirstPasswordData} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerSetFirstPassword: async (data: SetFirstPasswordData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('userManagerSetFirstPassword', 'data', data)
            const localVarPath = `/UserManager/setfirstpassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PrimaryCellData} cellData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerSetPrimaryCell: async (cellData: PrimaryCellData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cellData' is not null or undefined
            assertParamExists('userManagerSetPrimaryCell', 'cellData', cellData)
            const localVarPath = `/UserManager/setprimarycell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cellData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerTestEvent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserManager/testevent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserManagerApi - functional programming interface
 * @export
 */
export const UserManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OtpConfirmData} confirmData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagerCheckPrimaryOtp(confirmData: OtpConfirmData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagerCheckPrimaryOtp(confirmData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [guid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagerConfirmEmail(token?: string, guid?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagerConfirmEmail(token, guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagerGenerateRandomNo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagerGenerateRandomNo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterData} registerData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagerRegister(registerData: RegisterData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagerRegister(registerData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagerRegisterFromGoogle(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagerRegisterFromGoogle(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetFirstPasswordData} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagerSetFirstPassword(data: SetFirstPasswordData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagerSetFirstPassword(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PrimaryCellData} cellData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagerSetPrimaryCell(cellData: PrimaryCellData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagerSetPrimaryCell(cellData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userManagerTestEvent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userManagerTestEvent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserManagerApi - factory interface
 * @export
 */
export const UserManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserManagerApiFp(configuration)
    return {
        /**
         * 
         * @param {OtpConfirmData} confirmData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerCheckPrimaryOtp(confirmData: OtpConfirmData, options?: any): AxiosPromise<string> {
            return localVarFp.userManagerCheckPrimaryOtp(confirmData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [guid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerConfirmEmail(token?: string, guid?: string, options?: any): AxiosPromise<string> {
            return localVarFp.userManagerConfirmEmail(token, guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerGenerateRandomNo(options?: any): AxiosPromise<number> {
            return localVarFp.userManagerGenerateRandomNo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterData} registerData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerRegister(registerData: RegisterData, options?: any): AxiosPromise<string> {
            return localVarFp.userManagerRegister(registerData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerRegisterFromGoogle(options?: any): AxiosPromise<string> {
            return localVarFp.userManagerRegisterFromGoogle(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetFirstPasswordData} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerSetFirstPassword(data: SetFirstPasswordData, options?: any): AxiosPromise<string> {
            return localVarFp.userManagerSetFirstPassword(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PrimaryCellData} cellData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerSetPrimaryCell(cellData: PrimaryCellData, options?: any): AxiosPromise<string> {
            return localVarFp.userManagerSetPrimaryCell(cellData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userManagerTestEvent(options?: any): AxiosPromise<string> {
            return localVarFp.userManagerTestEvent(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserManagerApi - object-oriented interface
 * @export
 * @class UserManagerApi
 * @extends {BaseAPI}
 */
export class UserManagerApi extends BaseAPI {
    /**
     * 
     * @param {OtpConfirmData} confirmData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagerApi
     */
    public userManagerCheckPrimaryOtp(confirmData: OtpConfirmData, options?: any) {
        return UserManagerApiFp(this.configuration).userManagerCheckPrimaryOtp(confirmData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {string} [guid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagerApi
     */
    public userManagerConfirmEmail(token?: string, guid?: string, options?: any) {
        return UserManagerApiFp(this.configuration).userManagerConfirmEmail(token, guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagerApi
     */
    public userManagerGenerateRandomNo(options?: any) {
        return UserManagerApiFp(this.configuration).userManagerGenerateRandomNo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterData} registerData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagerApi
     */
    public userManagerRegister(registerData: RegisterData, options?: any) {
        return UserManagerApiFp(this.configuration).userManagerRegister(registerData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagerApi
     */
    public userManagerRegisterFromGoogle(options?: any) {
        return UserManagerApiFp(this.configuration).userManagerRegisterFromGoogle(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetFirstPasswordData} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagerApi
     */
    public userManagerSetFirstPassword(data: SetFirstPasswordData, options?: any) {
        return UserManagerApiFp(this.configuration).userManagerSetFirstPassword(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PrimaryCellData} cellData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagerApi
     */
    public userManagerSetPrimaryCell(cellData: PrimaryCellData, options?: any) {
        return UserManagerApiFp(this.configuration).userManagerSetPrimaryCell(cellData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagerApi
     */
    public userManagerTestEvent(options?: any) {
        return UserManagerApiFp(this.configuration).userManagerTestEvent(options).then((request) => request(this.axios, this.basePath));
    }
}


